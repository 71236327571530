<template>
    <div class="usage-box">
         <!-- 面包屑 -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>库存管理</el-breadcrumb-item>
            <el-breadcrumb-item>物料使用记录</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 顶部栏 -->
        <div class="header-bar">
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item v-if="addRule">
                    <el-button round plain @click="download" type="success" size="small" icon="el-icon-download">导出</el-button>
                </el-form-item>
                <el-form-item label="物料编号">
                     <el-input v-model="form.key"  placeholder="输入物品或编号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-autocomplete
                    class="inline-input"
                    v-model="form.staff_name"
                    :fetch-suggestions="getStaff"
                    placeholder="请输入员工"
                    @select="handleSelect"
                    ></el-autocomplete>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                    v-model="form.timeArr"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button class="margin" @click="search()" icon="el-icon-search" size="small">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- list -->
        <div class="usage-con">
            <el-table
            border
            v-loading="loading"
            :data="list"
            style="width: 100%">
                <el-table-column
                    label="日期">
                    <template slot-scope="scope">
                        {{scope.row.time|dateFormat}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="staff_name"
                    label="使用人">
                </el-table-column>
                <el-table-column
                    prop="tid"
                    label="工单id">
                </el-table-column>
                <el-table-column
                    prop="client_name"
                    label="使用门店">
                </el-table-column>
                <el-table-column
                    prop="num"
                    label="物料编号">
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="物料名称">
                </el-table-column>
                <el-table-column
                    prop="t_usage"
                    label="用量">
                </el-table-column>
                <el-table-column
                    prop="unit"
                    label="单位">
                </el-table-column>
                <el-table-column
                    prop="time"
                    label="时间">
                </el-table-column>
            </el-table>
        </div>
        <!-- page分页 -->
        <div class="pageNav" v-if="list.length>=1">
            <el-pagination
                hide-on-single-page
                v-model="page"
                layout="prev, pager, next"
                @current-change="currentChange"
                :page-count="totalPage">
            </el-pagination>
            <el-dropdown>
                <span class="el-dropdown-link">
                    每页显示 {{pageSize}} 条<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="choisePageSize(item)" v-for="item in pageArr" :key="item">
                        {{item}}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>
<script>
import {exportExcel} from '@/utils/excel.js'
import {formatTime,checkRules} from '@/utils/util.js'
export default {
    data(){
        return{
            addRule:checkRules(12),//添加权限
            form:{
                timeArr:'',
                key:"",
                staff_name:""
            },
            pageSize:10,
            page:1,
            list:[],
            staff:"",
            pageArr:[5,10,15,20] //每页显示
        }
    },
    created(){
        //初始化数据
        let now = formatTime(new Date) 
        ,str = now.split(" ")[0]
        let start = str.split("-"),
        start_time = `${start[0]}/${start[1]}/01 00:00:00`
        //console.log('start',start_time,new Date(start_time).getTime(),new Date(new Date().getTime()+24*60*60*1000))
        this.form.timeArr=[new Date(start_time),new Date(new Date().getTime()+24*60*60*1000)]
        this.search()
    },
    methods:{
         //获取员工姓名
        async  getStaff(queryString, cb){
            //console.log(this.form.staff_name)
            //console.log(queryString)
            let staffList=[]
            ,params = {
                    method:"getStaffList",
                    page:0,
                    keys:this.form.staff_name,
                    pageSize:10
                }
                let {data} =await this.$ajax.post(this.API.api,params)
                // console.log(data)
                // console.log(data.list)
                if(data.list){
                    data.list.forEach((item)=>{
                        staffList.push({
                            value:item.username,
                            id:item.userid
                        })
                    })
                    this.staffList = staffList
                    //搜索
                    this.querySearchStaff(queryString, cb)
                }
        },
        handleSelect(e){
            if(e.value)
            this.staff = e.value
        },
         querySearchStaff(queryString, cb) {
            var staffList = this.staffList;
            //console.log(staffList)
            var results = queryString ? staffList.filter(this.createFilterStaff(queryString)) : staffList;
            // 调用 callback 返回建议列表的数据
            //console.log(results,staffList)
            cb(results);
        },
        createFilterStaff(queryString) {
            return (staffList) => {
            return (staffList.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        choisePageSize(pageSize){
            this.pageSize = pageSize 
            this.search()
        },
        //点击页码
        currentChange(e){
            this.page = e 
            this.search()
        },
        download(){
            this.search(res=>{
                let columnTitle=['日期','使用人','工单id','使用门店','物料编号','物料名称','使用量','单位']
                let columnData = []
                res.list.forEach(it=>{
                        let date = it.time.split(" ")[0]
                        columnData.push([date,it.staff_name,it.tid,it.client_name,it.num,it.name,it.t_usage,it.unit])
                })
                exportExcel(columnTitle, columnData, formatTime(this.form.timeArr[0])+'-'+formatTime(this.form.timeArr[1])+"物料使用记录");
                
            })
        },
        search(callback){
            let params = {
                method:'getUsageAll',
                pageSize:callback?'100000':this.pageSize,
                page:callback?'1':this.page,
                key:this.form.key,
                staff:this.staff,
                start:formatTime(this.form.timeArr[0]),
                end:formatTime(this.form.timeArr[1])
            }
            //console.log(params)
            this.loading=true
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                //console.log(res.data)
                this.loading=false
                if(callback){
                    return callback(res.data)
                }
                if(res.data)
                this.list=res.data.list
                this.totalPage=res.data.totalPage
            }).catch(err=>{
                //err
                this.loading=false 
                this.$message({
                    type: 'warning',
                    message: err.msg?err.msg:err
                }); 
                console.log(err.data)
            })
        }
    }
}
</script>